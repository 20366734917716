import React, { useState } from "react";
import "./home.css";
import { useHealthContext } from "../context/appContext";
import ErrorMessage from "../containers/errorMessage";
import axios from "axios";

function PhoneNumber({ onNext }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
    sessionValue,
  } = useHealthContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (phoneNumber.length === 10) {
      onNext(phoneNumber);
      
    } else {
      setErrorMessage("Please enter 10 digits");
      // setErrorMessage("Please enter 10 digits");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    setErrorMessage("");
    let phoneNumber = input.replace(/\D/g, ""); // Use let instead of const
    const firstDigit = phoneNumber.charAt(0);

    if (firstDigit >= "6" && firstDigit <= "9") {
      // Compare with strings for consistent type
      phoneNumber = phoneNumber.slice(0, 10);
      setPhoneNumber(phoneNumber);
    } else {
      setPhoneNumber(""); // Clear phone number if not valid
      setErrorMessage(
        "Please enter a valid 10-digit phone number starting with 6-9"
      );
    }
  };



  return (
    <div className="outside-container">
      <div className="question-container">
        <form onSubmit={handleSubmit}>
          <div className="question-form">
            <div className="form-group">
              <div className="question">
                <p>Please leave your phone number below</p>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  required
                  placeholder="Your Phone Number"
                  className="input border-b focus:border-green-900"
                  autoFocus={true}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </div>
              <div className="button-container">
                <button type="submit">Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PhoneNumber;
