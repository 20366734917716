import React, { useState, useEffect } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";

const Questioneleven = ({onNext}) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } = useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.eating_habits || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value)
      
    } else {
      setErrorMessage("Please select your eating habits.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null)
    setValue(event.target.value);
  };

  return (
    <ContainerWithTitle title="Describe your eating habits,">
      <div className="radio-container flex lg:flex-row flex-col whitespace-pre">
        <RadioInput
          label="Vegetarian"
          value="Vegetarian"
          checked={value === "Vegetarian"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Vegetarian with eggs"
          value="Vegetarian with eggs"
          checked={value === "Vegetarian with eggs"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Non-vegetarian"
          value="Non-vegetarian"
          checked={value === "Non-vegetarian"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Vegan"
          value="Vegan"
          checked={value === "Vegan"}
          onChange={handleRadioChange}
        />
      </div>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default Questioneleven;
