import React, { useState } from "react";
import "./home.css";
import { useHealthContext } from "../context/appContext";

function Weight({ onNext }) {
  const [weight, setWeight] = useState("");
  const { payload, setPayload, currentPage, setCurrentPage } =
    useHealthContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (weight) {
      onNext(weight);
    }
  };

  const handleWeight = (event) => {
    const input = event.target.value;
    const weight = input.replace(/\D/g, "").slice(0, 3);
    setWeight(weight);
  };

  return (
    <div className="outside-container">
      <div className="question-container">
        <form onSubmit={handleSubmit}>
          <div className="question-form">
            <div className="form-group">
              <div className="question">
                <p>What is your weight in Kgs?</p>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  id="weight"
                  name="weight"
                  required
                  placeholder="Your weight in kilograms"
                  className="input border-b focus:border-green-900"
                  value={weight}
                  onChange={handleWeight}
                  autoFocus={true}
                />
              </div>
              <div className="button-container">
                <button type="submit">Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Weight;
