import React, { useState } from "react";
import "./home.css";
import { useHealthContext } from "../context/appContext";
import ErrorMessage from "../containers/errorMessage";

function Age({ onNext }) {
  const [age, setAge] = useState("");
  // const [errorMessage, setErrorMessage] = useState(null);

  const { payload, setPayload, currentPage, setCurrentPage,errorMessage, setErrorMessage } =
    useHealthContext();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (age < 18) {
      setErrorMessage(
        "Apologies. We cannot move further as the quiz is valid only for people above 18 years of age."
      );
    } else {
      if (age <= 110) {
        onNext(age)
      } else {
        setErrorMessage("Please enter age below 110");
      }
    }
  };

  const handleAge = (event) => {
    // event.preventDefault();
    
    const input = event.target.value;
    setErrorMessage("");
    const age = input.replace(/\D/g, "").slice(0, 3);

    setAge(age);
  };

  return (
    <div className="outside-container">
      <div className="question-container">
        <form onSubmit={handleSubmit}>
          <div className="question-form">
            <div className="form-group">
              <div className="question">
                <p>What's your age?
                </p>
              </div>
              <div className="input-container max-w-96">
                <input
                  type="text"
                  id="age"
                  name="age"
                  required
                  placeholder="Your age"
                  className="input border-b focus:border-green-900"
                  autoFocus={true}

                  value={age}
                  onChange={handleAge} 
                  
                />
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </div>
              <div className="button-container">
                <button type="submit">Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Age;
