import React, { useState } from "react";
import "./home.css";
import { useHealthContext } from "../context/appContext";
import axios from "axios";
import { apiUrl } from "../config/config";

function Name({ onNext }) {
  const [name, setName] = useState("");
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useHealthContext();

  // console.log("console", payload);

  const handleSubmit = (event) => {
    event.preventDefault();
    sessionCreate()
    onNext(name);
  };

  const sessionCreate = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${apiUrl}/api/v1/session/create`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          sessionStorage.setItem("quiz_Id", response.data.sessionId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;

    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value)) {
      setName(value);
    }
  };

  return (
    <div className="outside-container">
      <div className="question-container">
        <form onSubmit={handleSubmit}>
          <div className="question-form">
            <div className="form-group">
              <div className="question">
                <p>Before we get started, can we get your name?</p>
              </div>
              <div className="input-container">
                <input
                  type="text" // Corrected from "tezt" to "text"
                  id="name"
                  name="name"
                  required
                  placeholder="Your Name"
                  className="input border-b focus:border-green-900"
                  value={name}
                  onChange={handleChange}
                  autoFocus={true}
                />
              </div>
              <div className="button-container">
                <button type="submit">Next</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Name;
