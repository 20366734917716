import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import ContainerWithTitle from "../containers/title.container";
import CheckboxInput from "../containers/input.checkbox";
import ButtonContainer from "../containers/button";

const predefinedConditions = [
  "milk and milk products",
  "nuts","gluten/wheat",
  "fish",
  "eggs",
  "Not Applicable",
];

const QuestionTwelve = ({ onNext }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useHealthContext();
  const [value, setValue] = useState([]);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    const conditions = payload?.allergic || [];
    const knownConditions = conditions.filter((condition) =>
      predefinedConditions.includes(condition)
    );
    const unknownConditions = conditions.filter(
      (condition) => !predefinedConditions.includes(condition)
    );

    setValue(knownConditions);

    if (unknownConditions.length > 0) {
      setOtherValue(unknownConditions[0]);
      setIsOtherChecked(true);
    }
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isOtherChecked === true) {
      if (otherValue.trim() === "") {
        setErrorMessage("Please provide a value for 'Others'");
        return;
      } else {
        return onNext([...value, otherValue]);
      }
    }

    if (value.length > 0) {
      onNext(value);
    } else {
      setErrorMessage("Please select at least one.");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value: checkboxValue, checked } = event.target;
    if (checked) {
      setErrorMessage(null);
      setValue([...value, checkboxValue]);
    } else {
      setValue(value.filter((symptom) => symptom !== checkboxValue));
    }
  };

  const HandlerOtherCheckbox = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setIsOtherChecked(true);
    } else {
      setIsOtherChecked(false);
      setOtherValue("");
    }
  };

  const handleOtherInputChange = (e) => {
    setErrorMessage(null);
    setOtherValue(e.target.value);
  };

  const isCheckboxChecked = (checkboxValue) => {
    return value.includes(checkboxValue);
  };

  return (
    <ContainerWithTitle title="Are you allergic/intolerant to any of the following food/s? Check all that apply.">
      <ul>
        <CheckboxInput
          label="milk and milk products"
          value="milk and milk products"
          checked={isCheckboxChecked("milk and milk products")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="nuts"
          value="nuts"
          checked={isCheckboxChecked("nuts")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="gluten/wheat"
          value="gluten/wheat"
          checked={isCheckboxChecked("gluten/wheat")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="eggs"
          value="eggs"
          checked={isCheckboxChecked("eggs")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="fish"
          value="fish"
          checked={isCheckboxChecked("fish")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Not Applicable"
          value="Not Applicable"
          checked={isCheckboxChecked("Not Applicable")}
          onChange={handleCheckboxChange}
        />
        <CheckboxInput
          label="Others"
          value="Others"
          checked={isOtherChecked}
          onChange={HandlerOtherCheckbox}
        />

        {isOtherChecked && (
          <input
            type="text"
            placeholder="Please specify"
            value={otherValue}
            className="input mt-5"
            onChange={handleOtherInputChange}
          />
        )}
      </ul>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default QuestionTwelve;
