import React, { useState } from 'react';
import './home.css';
import { useHealthContext } from '../context/appContext';


function QuestionSix({ onNext }) {
    const { currentPage, setCurrentPage, setErrorMessage } =
    useHealthContext();

    const handleSubmit = (event) => {
        event.preventDefault();
        onNext()
       
    };




    return (
        <div className='outside-container'>
            <div className='heading-container'>
                <div className='heading'>
                    <p>Great! You seem to have normal stool.</p>
                </div>
                <div>
                    <p>Normal stool indicates that you are taking in right amount of fiber, water and exercising regularly.</p>
                </div>
                <div className='button-container'>
                    <button type="submit" onClick={handleSubmit}>Continue</button>
                </div>
            </div>
        </div>
    );
}

export default QuestionSix;
