import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import ContainerWithTitle from "../containers/title.container";
import CheckboxInput from "../containers/input.checkbox";
import ButtonContainer from "../containers/button";

const predefinedConditions = [
  "Diabetes",
  "Hypothyroidism",
  "PCOS",
  "High Blood Pressure",
  "High Cholestrol Level",
  "Heart Disease",
];

const QuestionTen = ({ onNext }) => {
  const { payload, setPayload, currentPage, setCurrentPage, setErrorMessage } =
    useHealthContext();
  const [value, setValue] = useState([]);
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [otherValue, setOtherValue] = useState("");

  useEffect(() => {
    const conditions = payload?.diagnosed_conditions || [];
    const knownConditions = conditions.filter((condition) =>
      predefinedConditions.includes(condition)
    );
    const unknownConditions = conditions.filter(
      (condition) => !predefinedConditions.includes(condition)
    );

    setValue(knownConditions);

    if (unknownConditions.length > 0) {
      setOtherValue(unknownConditions[0]); 
      setIsOtherChecked(true);
    }
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isOtherChecked === true) {
      if (otherValue.trim() === "") {
        setErrorMessage("Please provide a value for 'Others'");
        return;
      } else {
        return onNext([...value, otherValue]);
      }
    }

    if (value.length > 0) {
      onNext(value);
    } else {
      setErrorMessage("Please select at least one symptom.");
    }
  };

  const handleCheckboxChange = (event) => {
    const { value: checkboxValue, checked } = event.target;
    if (checked) {
      setValue([...value, checkboxValue]);
    } else {
      setValue(value.filter((symptom) => symptom !== checkboxValue));
    }
  };

  const handleOtherCheckboxChange = (event) => {
    const { checked } = event.target;
    if (checked) {
      setIsOtherChecked(true);
    } else {
      setIsOtherChecked(false);
      setOtherValue("");
    }
  };

  const handleOtherInputChange = (e) => {
    setErrorMessage(null);
    setOtherValue(e.target.value);
  };

  return (
    <ContainerWithTitle title="Do you have any of the following diagnosed conditions? Check all that apply.">
      <ul>
        {predefinedConditions.map((condition) => (
          <CheckboxInput
            key={condition}
            label={condition}
            value={condition}
            checked={value.includes(condition)}
            onChange={handleCheckboxChange}
          />
        ))}
        <CheckboxInput
          label="Others"
          value=""
          checked={isOtherChecked}
          onChange={handleOtherCheckboxChange}
        />
        {isOtherChecked && (
          <input
            type="text"
            placeholder="Please specify"
            value={otherValue}
            className="input mt-5"
            onChange={handleOtherInputChange}
          />
        )}
      </ul>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default QuestionTen;
