import React from "react";
import "./home.css";
import { useHealthContext } from "../context/appContext";
import Loader from "./loader";

function LastPage({ onFinish }) {
  const { loading } =
    useHealthContext();

  const handleFinish = () => {
    onFinish();
  };

  return (
    <div className="outside-container">
      <div className="heading-container">
        <div className="heading text-base md:text-lg " >
          <p>Thank you for completing the questionnaire!</p>
        </div>
        <div className="button-container relative w-full  ">
          {loading ? (
            <div className="mt-10 w-full">
              <Loader />
            </div>
          ) : (
            <button onClick={handleFinish} className="relative w-full text-xs md:text-base  ">
              View Report
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default LastPage;
