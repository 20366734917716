// ContainerWithTitle.js
import React from "react";
import ErrorMessage from "./errorMessage";
import { useHealthContext } from "../context/appContext";

const ContainerWithTitle = ({ title, title2, children }) => {
  const { errorMessage } = useHealthContext();
  // console.error(errorMessage)
  return (
    <div className="outside-container">
      <div className="question-container w-full">
        <div className="form-group">
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <div className="question text-left">
            <p>{title}</p>
            <h5 className=" text-xs lg:text-lg font-medium text-zinc-500">
              {title2}
            </h5>
          </div>
          <>{children}</>
        </div>
      </div>
    </div>
  );
};

export default ContainerWithTitle;
