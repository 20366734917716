import React, { useEffect, useState } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";
import ErrorMessage from "../containers/errorMessage";

const QuestionFive = ({onNext}) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    errorMessage,
    setErrorMessage,
  } = useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.exercise || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
     
      
      onNext(value)
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
  };

  return (
    <ContainerWithTitle
      title="Do you exercise or walk regularly?"
      title2="Regular physical exercise means at least 30 min of continuous walk or exercise."
    >
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          checked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          checked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default QuestionFive;
