import React, { useState, useEffect } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";

const QuestionEight = ({onNext}) => {
  const { payload, setPayload, currentPage, setCurrentPage , setErrorMessage} = useHealthContext();
  const [value, setValue] = useState("");

  
  
  useEffect(() => {
    setValue(payload?.sleep || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value)
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null)
    setValue(event.target.value);
  };

  return (
    <ContainerWithTitle title="How many hours of sleep do you get in a night on average?">
      <div className="radio-container flex lg:flex-row flex-col whitespace-pre">
        <RadioInput
          label="Less than 5 hours"
          value="below-5"
          checked={value === "below-5"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="Between 5-7 hours"
          value="5-7"
          checked={value === "5-7"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="7 or more hours"
          value="7-plus"
          checked={value === "7-plus"}
          onChange={handleRadioChange}
        />
      </div>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default QuestionEight;
