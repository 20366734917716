import React, { useState, useEffect } from "react";
import { useHealthContext } from "../context/appContext";
import RadioInput from "../containers/input.radio";
import ContainerWithTitle from "../containers/title.container";
import ButtonContainer from "../containers/button";

const Question9 = ({onNext}) => {
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    skipquestion,
    setSkipquestion,
    setErrorMessage,
  } = useHealthContext();
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(payload?.medical_condition || "");
  }, [currentPage, payload]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (value) {
      onNext(value);
      // if (val === "Yes") {
      // } else {
      //   setCurrentPage(currentPage + 2);
      // }
    } else {
      setErrorMessage("Please select an option.");
    }
  };

  const handleRadioChange = (event) => {
    setErrorMessage(null);
    setValue(event.target.value);
  };

  return (
    <ContainerWithTitle title="Do you have any existing medical condition?">
      <div className="radio-container">
        <RadioInput
          label="Yes"
          value="Yes"
          checked={value === "Yes"}
          onChange={handleRadioChange}
        />
        <RadioInput
          label="No"
          value="No"
          checked={value === "No"}
          onChange={handleRadioChange}
        />
      </div>
      <ButtonContainer onClick={handleSubmit}>Next</ButtonContainer>
    </ContainerWithTitle>
  );
};

export default Question9;
