import React, { useEffect, useRef, useState, useCallback } from "react";
import Home from "./components/Home";
import QuestionTwo from "./components/Email";
import Health_goals from "./components/Health_goals";
import QuestionFour from "./components/QuestionFour";
import QuestionFive from "./components/QuestionFive";
import QuestionSix from "./components/QuestionSix";
import LastPage from "./components/LastPage";
import "./App.css";
import QuestionSeven from "./components/QuestionSeven";
import QuestionEight from "./components/QuestionEight";
import QuestionNine from "./components/QuestionNine";
import PhoneNumber from "./components/PhoneNumber";
import Name from "./components/Name";
import Email from "./components/Email";
import { FaArrowLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import Age from "./components/age";
import Gender from "./components/gender";
import Weight from "./components/weight";
import Height from "./components/height";
import { useHealthContext } from "./context/appContext";
import Questioneleven from "./components/questioneleven";
import QuestionTen from "./components/questionTen";
import Questiontwilve from "./components/questiontwilve";
import Question13 from "./components/Question13";
import axios from "axios";
import Loader from "./containers/loader.js";
import { apiUrl } from "./config/config.js";

function App() {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const {
    payload,
    setPayload,
    currentPage,
    setCurrentPage,
    currentTab,
    setCurrentTab,
    skipquestion,
    loading,
    setLoading,
    sessionValue,
  } = useHealthContext();

  const totalSteps = 17; // Update this based on the total number of steps

  const progressPercentage = ((currentPage / totalSteps) * 100).toFixed(0);

  const [healthgoals, setHealthgoals] = useState(null);
  const [medicalcondition, setMedicationCondition] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [IsQuizStarted, setIsQuizStarted] = useState(true);

  const payloadRef = useRef();

  useEffect(() => {
    payloadRef.current = payload;
  }, [payload]);

  
  
  // this useEffect work perfectly but optmize code is error occur in this then unComment it.

  // useEffect(() => {
  //   const hasValueIsTrue = () => {
  //     if (payload.health_goals) {
  //       return (
  //         payload.health_goals.includes("Bloating & Gas") ||
  //         payload.health_goals.includes("Constipation")
  //       );
  //     }
  //     return false;
  //   };

  //   const hasMedical_condition = () => payload.medical_condition === "Yes";
  //   const hasExercise = () => payload.exercise === "No";

  //   // console.warn("hasExercise",hasExercise());

  //   setHealthgoals(hasValueIsTrue());
  //   if (hasValueIsTrue() === false) {
  //     setPayload((prevData) => ({ ...prevData, gat_Symptom: null }));
  //   } else if (hasMedical_condition() === false) {
  //     setPayload((prevData) => ({ ...prevData, diagnosed_conditions: [] }));
  //   } else if (hasExercise() === true) {
  //     setPayload((prevData) => ({...prevData, phyiscal_activity: "light" }));
  //   }

  //   // const has
  // }, [payload.health_goals, payload.medical_condition,payload.exercise]);

  useEffect(() => {
    const hasValueIsTrue = () => {
      if (payload.health_goals) {
        return (
          payload.health_goals.includes("Bloating & Gas") ||
          payload.health_goals.includes("Constipation")
        );
      }
      return false;
    };

    const hasMedical_condition = () => payload.medical_condition === "Yes";
    const hasExercise = () => payload.exercise === "No";

    // console.warn("hasExercise", hasExercise());

    setHealthgoals(hasValueIsTrue());

    setPayload((prevData) => {
      let updatedData = { ...prevData };
      let needsUpdate = false;

      if (hasValueIsTrue() === false && prevData.gat_Symptom !== null) {
        updatedData.gat_Symptom = null;
        needsUpdate = true;
      } else if (
        hasMedical_condition() === false &&
        prevData.diagnosed_conditions.length > 0
      ) {
        updatedData.diagnosed_conditions = [];
        needsUpdate = true;
      } else if (
        hasExercise() === true &&
        prevData.phyiscal_activity !== "light"
      ) {
        updatedData.phyiscal_activity = "light";
        needsUpdate = true;
      }

      return needsUpdate ? updatedData : prevData;
    });
  }, [payload.health_goals, payload.medical_condition, payload.exercise]);

  useEffect(() => {
    if (payload.exercise === "No") {
      setPayload((prevData) => ({ ...prevData, phyiscal_activity: "light" }));
    }
  }, [payload.exercise]);

  const PrevHandler = () => {
    setCurrentPage((prevPage) => {
      if (healthgoals === false && prevPage === 9) {
        return prevPage - 2;
      } else if (
        payloadRef.current.medical_condition === "No" &&
        prevPage === 14
      ) {
        return prevPage - 2;
      } else if (payloadRef.current.exercise === "No" && prevPage === 11) {
        return prevPage - 2;
      } else {
        return prevPage - 1;
      }
    });
  };

  const HandleNext = (pageValue, pageName) => {
    setPayload((prevData) => ({ ...prevData, [pageName]: pageValue }));
    setCurrentPage((prevPage) => {
      if (pageName === "medical_condition" && pageValue === "No") {
        return prevPage + 2;
      } else if (pageName === "exercise" && pageValue === "No") {
        return prevPage + 2;
      } else {
        return prevPage + 1;
      }
    });
  };

  // console.warn("==>", currentPage);
  // console.log("==>", payload);
  const FinishHandler = () => {
    const sesssionPayload = {
      sessionId: sessionValue,
    };
    try {
      setLoading(true);
      setIsLoading(true);
      let sessionConfig = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/session/delete`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(sesssionPayload),
      };

      axios.request(sessionConfig).then((response) => {
        console.log(JSON.stringify("Session Delete", response.data));
        sessionStorage.removeItem("quiz_Id");
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiUrl}/api/v1/diagnose/create`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
      };

      // console.log(JSON.stringify(payload));
      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify("response.data", response.data.data._id));
          setIsLoading(true);
          if (response.data.success === true && response.data?.data._id) {
            window.location.href = `https://thehealthspanco.com/pages/report?healthspanid=${response.data?.data?._id}`;
            setCurrentPage(0);
            setPayload([]);
          } else {
            setLoading(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          setIsLoading(false);

          console.log(error);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  console.log("payload >>>", payload);

  // const SessionUpdateHandler = () => {
  //   // if (payload.email !== "" || payload.height !== "") {
  //   let data = {
  //     sessionId: sessionValue,
  //     formData: Array.isArray(payload) ? { payload } : payload,
  //   };

  //   let config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${apiUrl}/api/v1/session/update`,
  //     headers: {},
  //     data: data,
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       // Handle successful response
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  // }

  // console.log("storedValue=>>",storedValue)

  const Header = () => (
    <div className="flex  w-full  justify-center items-center relative py-3  bg-[#2d3d23] text-white">
      <div className="w-11/12 relative gap-2  flex flex-col items-start ">
        {/* logo */}
        <img
          src={`https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=240`}
          className={` w-24 lg:w-32   brightness-[15000%] cursor-pointer`}
          onClick={() => {
            window.location.href = "https://thehealthspanco.com/";
          }}
        />
        <div className=" text-sm lg:text-base">Weight & Waist Assessment.</div>
      </div>
    </div>
  );

  return !IsQuizStarted ? (
    <div className="App gap-10">
      {isLoading && <Loader />}

      <header className="header w-full flex flex-col gap-2 items-center  ">
        {/* <div className="flex  w-full  justify-center items-center relative py-3  bg-[#2d3d23] text-white">
          <div className="w-11/12 relative gap-2  flex flex-col items-start ">
            <img
              src={`https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=240`}
              className={` w-24 lg:w-32   brightness-[15000%] cursor-pointer`}
              onClick={() => {
                window.location.href = "https://thehealthspanco.com/";
              }}
            />
            <div className=" text-sm lg:text-base">
              Weight & Waist Assessment.
            </div>
          </div>
        </div> */}

        <Header />
        <div className="w-11/12 lg:w-8/12 flex flex-col  items-center gap-3">
          <div
            className={`nav gap-5  ${
              currentPage === 0 ? "justify-end" : "justify-between"
            }
               
            `}
          >
            {currentPage !== 0 && (
              <button
                onClick={PrevHandler}
                disabled={currentPage < 1}
                className="previous-btn-container"
              >
                <div className="previous-btn-icon">
                  <FaArrowLeft />
                </div>
                <div
                  className={`previous-btn-text cursor-pointer  text-white px-4 py-1 rounded-md flex items-center gap-1  ${
                    currentPage !== 0
                      ? "bg-[#2d3d23]"
                      : "bg-zinc-100 border text-zinc-500"
                  } `}
                >
                  <FaArrowLeft /> Previous
                </div>
              </button>
            )}

            <button
              onClick={() => {
                setCurrentPage(0);
                setPayload("");
                window.location.href = "https://thehealthspanco.com/";
              }}
              className="exit-btn-container"
            >
              <div className="exit-btn-icon bg-[#2d3d23] border  text-white px-4 py-1 rounded-md text-xs">
                {/* <RxCross2 /> */}
                Exit
              </div>
              <div className="exit-btn-text cursor-pointer bg-[#2d3d23] text-white px-4 py-1 rounded-md  ">
                Exit
              </div>
            </button>
          </div>
          <div className="progress-container  gap-1 lg:gap-4 lg:text-base md:text-sm text-[0.6rem]">
            <div
              className={`progress-box lg:py-3 py-2 max-[410px]:text-[0.53rem] ${
                currentTab === "vitals" ? "active" : ""
              }`}
            >
              Vitals
            </div>
            <div
              className={`progress-box lg:py-3 py-2 max-[410px]:text-[0.53rem] ${
                currentTab === "goals" ? "active" : ""
              }`}
            >
              Goals
            </div>
            <div
              className={`progress-box lg:py-3 py-2 max-[410px]:text-[0.53rem] ${
                currentTab === "movement&sleep" ? "active" : ""
              }`}
            >
              Movement & Sleep
            </div>
            <div
              className={`progress-box lg:py-3 py-2 max-[410px]:text-[0.53rem]  ${
                currentTab === "diet&health" ? "active" : ""
              }`}
            >
              Diet & Health
            </div>
          </div>
          <div className="progress-bar-container w-2/3">
            <div className="progressPercentage">
              <p>{progressPercentage}%</p>
            </div>
            <div className="progress-bar ">
              <div
                className="progress"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      </header>

      {/* {currentPage === 0 && <Home onNext={() => setStep(1)} />} */}
      {currentPage === 0 && (
        <Name onNext={(data) => HandleNext(data, "name")} />
      )}
      {currentPage === 1 && (
        <PhoneNumber onNext={(data) => HandleNext(data, "phone")} />
      )}
      {currentPage === 2 && (
        <Email
          onNext={(data) => HandleNext(data, "email")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 3 && <Age onNext={(data) => HandleNext(data, "age")} />}
      {currentPage === 4 && (
        <Gender onNext={(data) => HandleNext(data, "gender")} />
      )}
      {currentPage === 5 && (
        <Weight onNext={(data) => HandleNext(data, "weight")} />
      )}
      {currentPage === 6 && (
        <Height
          onNext={(data) => HandleNext(data, "height")}
          // onClick={SessionUpdateHandler}
        />
      )}
      {currentPage === 7 && (
        <Health_goals onNext={(data) => HandleNext(data, "health_goals")} />
      )}
      {currentPage === 8 && (
        <QuestionFour onNext={(data) => HandleNext(data, "gat_Symptom")} />
      )}
      {currentPage === 9 && (
        <QuestionFive onNext={(data) => HandleNext(data, "exercise")} />
      )}
      {currentPage === 10 && (
        <QuestionSeven
          onNext={(data) => HandleNext(data, "phyiscal_activity")}
        />
      )}
      {currentPage === 11 && (
        <QuestionEight onNext={(data) => HandleNext(data, "sleep")} />
      )}
      {currentPage === 12 && (
        <QuestionNine
          onNext={(data) => HandleNext(data, "medical_condition")}
        />
      )}
      {currentPage === 13 && (
        <QuestionTen
          onNext={(data) => HandleNext(data, "diagnosed_conditions")}
        />
      )}
      {currentPage === 14 && (
        <Questioneleven onNext={(data) => HandleNext(data, "eating_habits")} />
      )}
      {currentPage === 15 && (
        <Questiontwilve onNext={(data) => HandleNext(data, "allergic")} />
      )}
      {currentPage === 16 && (
        <Question13 onNext={(data) => HandleNext(data, "medication")} />
      )}
      {currentPage === 17 && (
        <LastPage onFinish={(data) => FinishHandler(data)} />
      )}
    </div>
  ) : (
    <div className="h-screen flex flex-col     text-center w-full backdrop-blur-lg ">
      <Header />
      <div className="flex flex-col gap-3 lg:gap-10 items-center pt-32">
        <div className="font-bold text-xl lg:text-4xl w-[80%] lg:w-auto   text-[#2d3d23]  ">
          Generate your personalized weight loss plan.
        </div>
        <div className="font-bold text-sm lg:text-3xl pb-3  text-[#2d3d23]">
          Click below to take a 2-min quiz.
        </div>
        <button
          className="bg-[#2d3d23] text-sm lg:text-xl px-8 lg:px-10 mt-10 lg:p-3 p-2 text-white capitalize rounded"
          onClick={() => setIsQuizStarted(false)}
        >
          Click to Continue
        </button>
      </div>
    </div>
  );
}

export default App;
