const ButtonContainer = ({ children , onClick }) => {
  return (
    <div className="button-container">
      <button type="submit" onClick={onClick}>
        {children}
      </button>
    </div>
  );
};

export default ButtonContainer;